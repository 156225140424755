import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN_URL, TOKEN_KEY } from 'config';
import { PrivateLayout } from 'layout/PrivateLayout';

const PrivateRouteMode = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props =>
        window.localStorage.getItem(TOKEN_KEY) ? (
          <PrivateLayout>
            <Component {...props} />
          </PrivateLayout>
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_URL,
            }}
          />
        )
      }
    />
  );
};

export { PrivateRouteMode as PrivateRoute };
