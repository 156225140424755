import React from 'react';
import { Route } from 'react-router-dom';
import { PublicLayout } from 'layout/PublicLayout';

export const PublicRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={props => (
      <PublicLayout>
        <Component {...props} />
      </PublicLayout>
    )}
  />
);
