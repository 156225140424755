import React from 'react';

const Counter = React.lazy(() => import('components/Counter'));
const Home = React.lazy(() => import('pages/Home/Home'));

export interface RouteCustom {
  path: string;
  exact?: true;
  component: any;
  isPrivate?: boolean;
}

const routes: RouteCustom[] = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  // {
  //   path: '/login',
  //   exact: true,
  //   component: LoginPage
  // }
];

export default routes;
